<template>

  <div style="transition: all 0.25s ease-out; display: flex;place-content: center;align-items: flex-start;flex-direction: column; overflow: hidden" 
    :style="`padding: ${state.modeSearch == 'simple' ? '10%' : '0%'}; 
             height: ${state.modeSearch == 'simple' ? 'calc(100vh - 222px)' : 'initial'}; `" >

    <div :style="`filter: ${state.modeSearch == 'complex' && !state.isAuth  ? 'opacity(0.2)' : 'opacity(1)'}; 
                  height: ${state.modeSearch == 'complex' && !state.isAuth  ? 'calc(100vh - 40px)' : 'initial'};
    `" style="width: 100%" >
    
      <object class="objLogo" data="media/still/logo_white.svg" style="width: 640px; margin-left: -50px; margin-bottom: 50px;" :style="`display: ${state.modeSearch == 'simple' ? 'block' : 'none'}`"> </object>
      <div style="width: 100%">
        <div
          class="d-flex align-items-center position-relative my-1"
          style="width: 100%"
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid ps-15"
            v-model="state.search"
            style="width: 100% !important; height: 80px; border-radius: 5px;font-size: 20px;"
            placeholder="Rechercher un ou des produits en saisissant le SKU"
            v-on:keyup.enter="searchAPi(state.search)"
          />
        </div>



        <template v-if="state.cSearch == 1">
          <div href="#" style="float: right; position: relative; margin-top: -66px; right: 20px; cursor : default; color: #797979; font-weight: 600; font-size: 16px;" class="">
            <img src="media/icons/loader.gif" />
          </div>
        </template>
        <template v-else>
          <div v-if="state.search.length > 3" href="#" style="float: right; position: relative; margin-top: -67px; right: 20px;" class="btn btn-lg btn-primary shadow"
            @click = "searchAPi(state.search)"
           >Lancer la recherche
          </div>
        </template>

        <div class="card" style="width: 100%; margin-top: 20px; border-radius: 5px;" :style="`display: ${state.modeSearch == 'simple' ? 'none' : 'block'}`">
          <div class="card-header border-0 pt-6">
            <div class="card-title" style="width:100%;">
              <h2>Résultat de votre recherche "{{ state.searchTitle }}, xx référ"</h2>
            </div>
          </div>
          <div class="card-body pt-0">
            <div
              v-if="state.myList.length == 0"
              class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
            >
              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-dark">Aucune ligne à afficher</h4>
                <span>Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.</span>
              </div>
            </div>
            <Datatable
              v-if="state.myList && state.myList.length > 0"
              :table-data="state.myList"
              :table-header="tableHeader"
              :enable-items-per-page-dropdown="true"
              :rows-per-page="25"
              empty-table-text="Aucune ligne à afficher."
              :key="state.loadingDatatable"
              class="table-small-td"
              order ='desc'
              sortLabel="qte"
            >
              <template v-slot:cell-ref="{ row: data }"><span style="color:#000;" class="fs-4">{{ data.ref }}</span></template>
              <template v-slot:cell-desc="{ row: data }"><span style="color:#959595;">{{ data.desc }}</span></template>
              <template v-slot:cell-qte="{ row: data }">

                <template v-if="state.isAuth">
                  <span v-if="data.qte > 0"
                    class="badge badge-success badge-circle w-15px h-15px me-1"
                  ></span>
                  <b class="fs-3" v-if="data.qte > 0">&nbsp;{{ data.qte  }}</b>
                  <span v-if="data.qte == 0"
                    class="badge badge-danger badge-circle w-15px h-15px me-1"
                  ></span>
               </template>

              </template>

              <template v-slot:cell-leadtime="{ row: data }">
                <span v-if="state.isAuth" style="color:#000;">{{ formatDate(data.leadtime) }}</span>
                <br>
                <span v-if="state.isAuth" style="color:#959595;" class="fs-7">{{ formatDateAgo(data.leadtime) }}</span>
              </template>

            </Datatable>
          </div>
        </div>      

      </div>
    </div>
    <div v-if="state.modeSearch == 'complex' &&  !state.isAuth" style="position: fixed;     width: calc(100% - 710px);    left: calc(680px);" >
      <div class="card jumbotron" style="padding: 20px; min-height: 300px">
        <h1 class="display-4" style="color: #007ab5;">Pour continuer, vous devez vous créer un compte.</h1>
        <p class="lead" style="font-size:29px;"><b>🚀 C'est simple, rapide, gratuit et sans engagement !</b></p>
        <p style="margin-left: 15px;font-size: 20px;margin-top: 20px;">Accèder à tout notre stock produits, <br>Veillez à avoir une vue claire sur chaque disponibilité<br>Vérifier les dates de réapprovisionnement, <br>...</p><br>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
  },
  setup() {

    const router = useRouter();

    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      search : "",
      results: [],
      lastSearch : "",
      cSearch:-1,
      modeSearch : "simple",
      myList: [] as any,
      initialMyList: [],
      loadingDatatable: 0,
      searchTitle : "",
      isAuth : store.getters.isUserAuthenticated,
    });

   
    const tableHeader = ref([
      { name: "Référence", key: "ref", sortable: true,},
      { name: "Produit", key: "desc", sortable: true,},
      { name: "Disponibilité", key: "qte", sortable: true,},
      { name: "Leadtime", key: "leadtime", sortable: true,},
      { name: "Produit", key: "id", sortable: true,},
    ]);


    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const tableFilters = ref([
      { name: "leadtime", key: "leadtime", label: "leadtime", },
    ]);

    
    /*
    watch(state, debounce(() => {
      searchAPi(state.search);
    }, 1000));
  */

    const searchAPi = async (search) => {
      if (search.length < 3) {
        return false;
      }
      if(state.lastSearch == search) return false;
      state.lastSearch = search;
      state.cSearch = 1;
      const mProduits = await getAxios("/getProducts/" + search);
      state.results = mProduits.results;
      state.myList = mProduits.results;
      state.initialMyList = mProduits.results;
      // console.log("mProduits", mProduits);
      state.cSearch = 0;
      state.loadingDatatable++;


      let strProd = '';
      mProduits.results.forEach(el => {
        strProd = strProd + '{"vendorPartNumber": "'+ el.ref +'"},';
      });

      changeModeSearch('complex');

      //console.log("str", strProd);

    };


    onMounted(async () => {  
      state.loaderEnabled = false;
    });


    const changeModeSearch = (mode) => {
      state.searchTitle = state.lastSearch;
      state.modeSearch = mode;
      state.cSearch= mode == 'simple' ? 0 : 2;
      if (!state.isAuth && mode == 'complex' ) affAddAccount();
      
    };

    const affAddAccount = () => {
      router.push({ name: "sign-up" });
    }


    return {
      state,
      changeModeSearch,
      tableHeader,
      tableFilters,
      formatDate,
      formatDateAgo,
      searchAPi
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>