
import { defineComponent, ref, onMounted, reactive } from "vue";
import store from "@/store";
import * as moment from "moment";
import mAxiosApi from "@/api";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
  },
  setup() {

    const router = useRouter();

    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      search : "",
      results: [],
      lastSearch : "",
      cSearch:-1,
      modeSearch : "simple",
      myList: [] as any,
      initialMyList: [],
      loadingDatatable: 0,
      searchTitle : "",
      isAuth : store.getters.isUserAuthenticated,
    });

   
    const tableHeader = ref([
      { name: "Référence", key: "ref", sortable: true,},
      { name: "Produit", key: "desc", sortable: true,},
      { name: "Disponibilité", key: "qte", sortable: true,},
      { name: "Leadtime", key: "leadtime", sortable: true,},
      { name: "Produit", key: "id", sortable: true,},
    ]);


    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const tableFilters = ref([
      { name: "leadtime", key: "leadtime", label: "leadtime", },
    ]);

    
    /*
    watch(state, debounce(() => {
      searchAPi(state.search);
    }, 1000));
  */

    const searchAPi = async (search) => {
      if (search.length < 3) {
        return false;
      }
      if(state.lastSearch == search) return false;
      state.lastSearch = search;
      state.cSearch = 1;
      const mProduits = await getAxios("/getProducts/" + search);
      state.results = mProduits.results;
      state.myList = mProduits.results;
      state.initialMyList = mProduits.results;
      // console.log("mProduits", mProduits);
      state.cSearch = 0;
      state.loadingDatatable++;


      let strProd = '';
      mProduits.results.forEach(el => {
        strProd = strProd + '{"vendorPartNumber": "'+ el.ref +'"},';
      });

      changeModeSearch('complex');

      //console.log("str", strProd);

    };


    onMounted(async () => {  
      state.loaderEnabled = false;
    });


    const changeModeSearch = (mode) => {
      state.searchTitle = state.lastSearch;
      state.modeSearch = mode;
      state.cSearch= mode == 'simple' ? 0 : 2;
      if (!state.isAuth && mode == 'complex' ) affAddAccount();
      
    };

    const affAddAccount = () => {
      router.push({ name: "sign-up" });
    }


    return {
      state,
      changeModeSearch,
      tableHeader,
      tableFilters,
      formatDate,
      formatDateAgo,
      searchAPi
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
